import { isObject } from 'types'

import { isStoryblokImageLink } from '../image-link/ImageLink.types'

import type { StoryblokImageLink } from '../image-link/ImageLink.types'

export type StoryblokSponsors = {
  primary_sponsors_b2c: StoryblokImageLink[]
  secondary_sponsors_b2c: StoryblokImageLink[]
  primary_sponsors_jeugd: StoryblokImageLink[]
  secondary_sponsors_jeugd: StoryblokImageLink[]
  primary_sponsors_b2b: StoryblokImageLink[]
  secondary_sponsors_b2b: StoryblokImageLink[]
}

export function isStoryblokSponsors(
  value: unknown
): value is StoryblokSponsors {
  if (isObject(value)) {
    // Primary sponsors b2c
    const { primary_sponsors_b2c } = value
    if (
      Array.isArray(primary_sponsors_b2c) &&
      primary_sponsors_b2c.length > 0 &&
      !primary_sponsors_b2c.every((item) => isStoryblokImageLink(item))
    ) {
      return false
    }
    // Secondary sponsors b2c
    const { secondary_sponsors_b2c } = value
    if (
      Array.isArray(secondary_sponsors_b2c) &&
      secondary_sponsors_b2c.length > 0 &&
      !secondary_sponsors_b2c.every((item) => isStoryblokImageLink(item))
    ) {
      return false
    }
    // Primary sponsors jeugd
    const { primary_sponsors_jeugd } = value
    if (
      Array.isArray(primary_sponsors_jeugd) &&
      primary_sponsors_jeugd.length > 0 &&
      !primary_sponsors_jeugd.every((item) => isStoryblokImageLink(item))
    ) {
      return false
    }
    // Secondary sponsors jeugd
    const { secondary_sponsors_jeugd } = value
    if (
      Array.isArray(secondary_sponsors_jeugd) &&
      secondary_sponsors_jeugd.length > 0 &&
      !secondary_sponsors_jeugd.every((item) => isStoryblokImageLink(item))
    ) {
      return false
    }
    // Primary sponsors b2b
    const { primary_sponsors_b2b } = value
    if (
      Array.isArray(primary_sponsors_b2b) &&
      primary_sponsors_b2b.length > 0 &&
      !primary_sponsors_b2b.every((item) => isStoryblokImageLink(item))
    ) {
      return false
    }
    // Secondary sponsors b2b
    const { secondary_sponsors_b2b } = value
    if (
      Array.isArray(secondary_sponsors_b2b) &&
      secondary_sponsors_b2b.length > 0 &&
      !secondary_sponsors_b2b.every((item) => isStoryblokImageLink(item))
    ) {
      return false
    }
  } else {
    return false
  }
  return true
}
