import NextHead from 'next/head'

import type { ISbStoryData } from '@storyblok/react'

type HeadProps = {
  story: ISbStoryData
  seo_metatags: ISbStoryData['content']['seo_metatags']
}

export const Head = ({ story, seo_metatags }: HeadProps) => {
  const title = seo_metatags?.title || `${story?.name} | Cercle Brugge KSV`
  const ogTitle = seo_metatags?.og_title || title
  const twitterTitle = seo_metatags?.twitter_title || title
  const description = seo_metatags?.description || ''
  const ogDescription = seo_metatags?.og_description || description
  const twitterDescription = seo_metatags?.twitter_description || description
  const ogImage = seo_metatags?.og_image
  const twitterImage = seo_metatags?.twitter_image || ogImage

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="og:title" content={ogTitle} />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="description" content={description} />
      <meta name="og:description" content={ogDescription} />
      <meta name="twitter:description" content={twitterDescription} />
      {ogImage && <meta name="og:image" content={ogImage} />}
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  )
}
