import { isObject } from 'types'

import { isStoryblokImageLink } from '../image-link/ImageLink.types'
import { isStoryblokTextLink } from '../text-link/TextLink.types'
import { isStoryblokTextLinksWithTitle } from '../text-links-with-title/TextLinksWithTitle.types'

import type { StoryblokImageLink } from '../image-link/ImageLink.types'
import type { StoryblokTextLink } from '../text-link/TextLink.types'
import type { StoryblokTextLinksWithTitle } from '../text-links-with-title/TextLinksWithTitle.types'

export type StoryblokFooter = {
  social_media_links: StoryblokImageLink[]
  logo: StoryblokImageLink[]
  sitemap: StoryblokTextLinksWithTitle[]
  company_name: string
  company_address: string
  contact_email: string
  contact_phone: string
  bottom_links: StoryblokTextLink[]
}

export function isStoryblokFooter(value: unknown): value is StoryblokFooter {
  if (isObject(value)) {
    // Social media links
    const { social_media_links } = value
    if (
      Array.isArray(social_media_links) &&
      social_media_links.length > 0 &&
      !social_media_links.every((item) => isStoryblokImageLink(item))
    ) {
      return false
    }
    // Main links
    const { sitemap } = value
    if (
      Array.isArray(sitemap) &&
      sitemap.length > 0 &&
      !sitemap.every((item) => isStoryblokTextLinksWithTitle(item))
    ) {
      return false
    }
    // Logo
    const { logo } = value
    if (
      Array.isArray(logo) &&
      logo.length > 0 &&
      !logo.every((item) => isStoryblokImageLink(item))
    ) {
      return false
    }
    // Bottom links
    const { bottom_links } = value
    if (
      Array.isArray(bottom_links) &&
      bottom_links.length > 0 &&
      !bottom_links.every((item) => isStoryblokTextLink(item))
    ) {
      return false
    }
  } else {
    return false
  }
  return true
}
