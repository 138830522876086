import { isObject } from 'types'

import { isStoryblokTextLink } from '../text-link/TextLink.types'

import type { StoryblokTextLink } from '../text-link/TextLink.types'

export type StoryblokTextLinksWithTitle = {
  _uid: string
  title: string
  links: StoryblokTextLink[]
}

export function isStoryblokTextLinksWithTitle(
  value: unknown
): value is StoryblokTextLinksWithTitle {
  if (isObject(value)) {
    // UID
    const { _uid } = value
    if (typeof _uid !== 'string') {
      return false
    }
    // UID
    const { title } = value
    if (typeof title !== 'string') {
      return false
    }
    // Links
    const { links } = value
    if (
      Array.isArray(links) &&
      links.length > 0 &&
      !links.every((item) => isStoryblokTextLink(item))
    ) {
      return false
    }
  } else {
    return false
  }
  return true
}
